.order-card {

    display: flex !important;
    padding: 10px !important;
    border-radius: 8px !important;
    margin: 0px 0px 20px 0px !important;
    box-shadow: none !important;
    border: 1px solid #e3e3e3 !important;
  }
  
  .order-card .shipment-content {
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
    justify-content: space-between !important;
  }
  
  .order-card .shipment-info {
    display: flex !important;
    align-items: center !important;
  }
  
  .order-card .shipment-icon {
    font-size: 33px !important;
    color: #7B7B7B !important;
    /* Adjust to match the grey color */
    margin-right: 8px !important;
  }
  
  .order-card .shipment-text {
    color: #7B7B7B !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    font-family: "Inter" !important;
  }
  
  .order-card .shipment-price {
    color:#7B7B7B !important;
    font-size: 16px !important;
    font-family: "Inter" !important;
    font-weight: 500 !important;
  }
  
  
  .order-card .shipment-button {
    background-color: #7B7B7B !important;
    color: white ! important;
    border-radius: 20px !important;
    padding: 10px 28px !important;
    font-size: 15px !important;
    box-shadow: none !important;
    font-weight: 500 !important;
    font-family: "Inter" !important;
  }
  
  
  /* .ModalPopUp .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  
  
      max-width: 490px !important;
      height: 400px !important;
      width: 600px !important;
      border-radius: 16px !important;
    }
  
    .ModalPopUp .css-ypiqx9-MuiDialogContent-root{
      display: flex  !important
  ;
      justify-content: center  !important;
      align-items: center  !important;
    }
  
    .ModalPopUp .css-ypiqx9-MuiDialogContent-root  .css-1pnmrwp-MuiTypography-root {
      color: #424545 !important;
      font-weight: 500 !important;
  
  
  
    }
  
    .ModalPopUp .css-knqc4i-MuiDialogActions-root{
  
      margin: auto !important;
    }
  
    .ModalPopUp .css-knqc4i-MuiDialogActions-root .css-10fi992-MuiButtonBase-root-MuiButton-root{
  
  
      color: white !important;
      background-color: #FF0000 !important;
      font-family: "Inter" !important;
      border-radius: 4px !important;
      margin-bottom: 10px !important;
      padding: 6px 37px !important;
    } */
  
  
  @media (max-width: 434px) {
  
    .order-card .shipment-button {
  
      padding: 4px 10px !important;
      font-size: 11px !important;
    }
  
    .order-card   .shipment-price {
  
      font-size: 11px !important;
    }
  
    .order-card .shipment-text {
  
      font-size: 11px !important;
  
    }
  
  }