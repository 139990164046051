/* ShareComponent.css */

.bottom-share {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    border-radius: 5px;
}

.share-btn {
    display: flex;
    align-items: center;
    /* background-color: #007bff; */
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    color: black;
    border: none;
    border-radius: 16px;
    padding: 12px 107px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    background: none;
    margin-top: 10px; /* Added margin for spacing */
    margin-bottom: 10px;
}

  
  .share-icon {
    margin-right: 8px; /* Adjust as needed to add space between the icon and the text */
    width: 16px; /* Adjust size as needed */
    height: 16px;
  }

.share-btn:hover {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}

.share-para {
    margin: 10px 0;
    font-size: 18px;
    font-weight: 400;
    color: black;
}

/* .divider{

    height: 5px;

    background-color: black;
    width: 100%;

} */

.share-options {
    display: flex;
    justify-content: center; /* Center icons */
    margin-bottom: 10px; /* Space below icons */
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s;
}

.share-options.show {
    opacity: 1; /* Show with transition */
}

.share-option {
    margin: 0 10px; /* Space between icons */
    cursor: pointer; /* Change cursor on hover */
}

.share-option:hover {
    color: #007bff; /* Change color on hover */
}

.fade-in {
    animation: fadeIn 0.5s forwards; /* Apply animation */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
