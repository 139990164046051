.shipment-card {

  display: flex !important;
  padding: 10px !important;
  border-radius: 8px !important;
  margin: 20px 0 !important;
  box-shadow: none !important;
  border: 1px solid #e3e3e3 !important;
}

.shipment-content {
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
  justify-content: space-between !important;
}

.shipment-info {
  display: flex !important;
  align-items: center !important;
}

.shipment-icon {
  font-size: 33px !important;
  color: rgba(153, 153, 153, 1) !important;
  /* Adjust to match the grey color */
  margin-right: 8px !important;
}

.shipment-text {
  color: rgba(153, 153, 153, 1) !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: "Inter" !important;
}

.shipment-price {
  color: rgba(153, 153, 153, 1) !important;
  font-size: 16px !important;
  font-family: "Inter" !important;
}


.shipment-button {
  background-color: #e0e0e0 !important;
  color: rgba(153, 153, 153, 1) ! important;
  border-radius: 20px !important;
  padding: 10px 28px !important;
  font-size: 15px !important;
  box-shadow: none !important;
  font-weight: 500 !important;
  font-family: "Inter" !important;
}


/* .ModalPopUp .css-1t1j96h-MuiPaper-root-MuiDialog-paper {


    max-width: 490px !important;
    height: 400px !important;
    width: 600px !important;
    border-radius: 16px !important;
  }

  .ModalPopUp .css-ypiqx9-MuiDialogContent-root{
    display: flex  !important
;
    justify-content: center  !important;
    align-items: center  !important;
  }

  .ModalPopUp .css-ypiqx9-MuiDialogContent-root  .css-1pnmrwp-MuiTypography-root {
    color: #424545 !important;
    font-weight: 500 !important;



  }

  .ModalPopUp .css-knqc4i-MuiDialogActions-root{

    margin: auto !important;
  }

  .ModalPopUp .css-knqc4i-MuiDialogActions-root .css-10fi992-MuiButtonBase-root-MuiButton-root{


    color: white !important;
    background-color: #FF0000 !important;
    font-family: "Inter" !important;
    border-radius: 4px !important;
    margin-bottom: 10px !important;
    padding: 6px 37px !important;
  } */


@media (max-width: 434px) {

  .shipment-button {

    padding: 4px 10px !important;
    font-size: 11px !important;
  }

  .shipment-price {

    font-size: 11px !important;
  }

  .shipment-text {

    font-size: 11px !important;

  }

}