.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    margin: 0px 0 !important;
}

.css-o5xng8-MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: 0px 0 !important;
}

.css-o5xng8-MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin-top: 10px !important;
}
.slick-track {

    margin-left: 0 !important;

}

/* .details-accordian .css-627m4c-MuiAccordionDetails-root h1  {
    margin-top: 0 !important;
  } */