.hr-divider {
    border: none;
    border-left: 1px solid #ccc;
    height: 15px; /* Ensure the height is defined */
    margin: 0 10px;
    margin-top: 15px;
  }
    
  .HeadWithLinks{
    display: flex;
    width: 35%;
    justify-content:space-between ;
    align-items: center;
    
  }
  /* Hide the divider when screen width is 600px or less */
  @media (max-width: 600px) {
    .hr-divider {
      display: none;
    }
  }

  @media (max-width: 1394px) {
    .HeadWithLinks {
    width: 40%;
    }
  }

  @media (max-width: 1157px) {
    .HeadWithLinks {
    width: 40%;
    }
  }

  @media (max-width: 600px) {
    .HeadWithLinks {
align-items: unset;
flex-direction: column;

    }
  }

