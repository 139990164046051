/* .CustomFormWrapper .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
    display: none !important;
  }

  .CustomFormWrapper .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input.MuiAutocomplete-inputFocused.css-1x51dt5-MuiInputBase-input-MuiInput-input {
    min-width: 60px !important;
  }
  
  .CustomFormWrapper .css-rei2g5-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input{
    min-width: 66px !important;
  } */

  .no-scroll {
    overflow: hidden;
  }