.responsive-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  

  @media (max-width: 900px) {
    .responsive-image {
        aspect-ratio: 16/8.1;
    
    }
  }